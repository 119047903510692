<template>
  <div>
    <v-tabs v-model="tab" background-color="info" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Pending
        <v-icon>mdi-file-chart-check</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Running
        <v-icon>mdi-file-chart-check</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        Completed
        <v-icon>mdi-file-chart-check</v-icon>
      </v-tab>
    </v-tabs>
    <v-data-table
      :headers="dessertHeaders"
      :items="getInvestments"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      class="elevation-1"
      :items-per-page="20"
      hide-default-footer
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.amount="{ item }">
        <span>{{ DisplayCurrency(item.amount) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>User Full Name</td>
                  <td>{{ item.userId.fullName }}</td>
                </tr>
                <tr>
                  <td>User Name</td>
                  <td>{{ item.userId.userName }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ item.userId.email }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="tab == 'tab-1'" class="text-center ma-4">
            <Activate :investment="item" />
          </div>
        </td>
      </template>
    </v-data-table>
    <div class="text-center mt-7">
      <v-pagination v-model="page" :length="totalItems" circle></v-pagination>
    </div>
  </div>
</template>

<script>
import currencyFormatter from "currency-formatter";
import Activate from "../components/ActivateInvestment.vue";
export default {
  components: { Activate },
  data() {
    return {
      tab: null,
      status: "Pending",
      page: 1,
      currentPage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Investment Name",
          align: "start",
          sortable: false,
          value: "investmentName",
        },
        { text: "coin", value: "coin", align: "center", sortable: false },
        {
          text: "Daily ReturnRate %",
          value: "dailyReturnRate",
          align: "center",
          sortable: false,
        },
        {
          text: "TotalReturnRat %e",
          value: "totalReturnRate",
          align: "center",
          sortable: false,
        },
        {
          text: "Amount Invested",
          value: "amount",
          align: "center",
          sortable: false,
        },
        { text: "Date", value: "createdAt", sortable: false, align: "center" },
      ],
    };
  },
  computed: {
    getInvestments() {
      return this.$store.getters.getInvestments;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    GetAllInvestmentWithStatus() {
      let payload = {
        token: this.getToken,
        page: this.page,
        status: this.status,
      };
      this.$store.dispatch("GetAllInvestmentWithStatus", payload);
    },
  },
  created() {
    this.GetAllInvestmentWithStatus();
  },
  watch: {
    page: function (val) {
      if (this.page != this.currentPage) {
        this.currentPage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
          status: this.status,
        };
        this.$store.dispatch("GetAllInvestmentWithStatus", payload);
      }
    },
    tab: function (val) {
      if (val == "tab-1") {
        this.currentPage = val;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "Pending",
        };
        this.$store.dispatch("GetAllInvestmentWithStatus", payload);
      }
      if (val == "tab-2") {
        this.currentPage = val;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "Running",
        };
        this.$store.dispatch("GetAllInvestmentWithStatus", payload);
      }
      if (val == "tab-3") {
        this.currentPage = val;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "Completed",
        };
        this.$store.dispatch("GetAllInvestmentWithStatus", payload);
      }
    },
  },
};
</script>